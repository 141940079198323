import { FirebaseOptions } from "@firebase/app";
import { ITranslation } from "@/types/components.types";
export enum IntegrationsType {
  TYPE_GOOGLE_ANALYTICS = 1,
  TYPE_FACEBOOK_PIXEL = 2,
  TYPE_HOTJAR = 3,
  TYPE_ZENDESK_CHAT = 4,
  TYPE_KLAVIYO = 5,
  TYPE_STRIPE_AFFIRM = 6,
  TYPE_PAYPAL = 7,
  TYPE_CDN_DOMAIN = 8,
  TYPE_CUSTOM_SCRIPTS = 9,
  TYPE_OPERATE_STATISTICS = 35,
  TYPE_IMPACT = 60,
  TYPE_GTM = 62,
  TYPE_FIREBASE = 63,
}

export interface CompanyAddressI {
  email_address: string | null;
  phone_number: string | null;
  address_line_1: string | null;
  city: string | null;
  state: string | null;
  country: string | null;
  zip: string | null;
}

export interface BootstrapSettings {
  desktop_logo: string;
  mobile_logo: string;
  footer_logo: string;
  logo_caption: string;
  slogan: string;
  summary: string;
  copyright: string;
  meta_title: string;
  meta_description: string;
  meta_keywords: string;
  head_scripts: string;
  start_body_scripts: string;
  end_body_scripts: string;
  packing_slip_disclaimer: string;
  front_domain: string;
  cdn_domain: string;
  instagram_token: string;
  company_name: string;
  allowed_ips: string[];
  shipping_is_free: boolean;
  date_format?: string;
  currency_code?: string;
  company_address?: CompanyAddressI;
  working_hours?: string | null;
  lead_time: number | null;
}

export interface PaypalModeConfig {
  client_id: string;
}

export interface BootstrapIntegrationCDN {
  domain: string;
}

export interface IntegrationCallbackData {
  integrationName: string;
  integrationObjectName: string;
  integrationScriptHasSrc: boolean;
}

export interface BootstrapIntegrationScript {
  script: string;
  position: "head" | "start_body" | "end_body";
  callbackData?: IntegrationCallbackData;
  events_enabled?: boolean;
}

export interface BootstrapIntegrationScripts {
  scripts: BootstrapIntegrationScript[];
}

export interface BootstrapIntegrationStripe {
  public_key: string;
}
export interface BootstrapIntegrationPayPal {
  mode: "live" | "sandbox";
  currency: string;
  locale: string;
  live?: PaypalModeConfig;
  sandbox?: PaypalModeConfig;
}

export interface BootstrapIntegrationGTM {
  gtm_id: string;
  verification_code: string;
}

export interface BootstrapIntegrationFirebase {
  sdk_config: FirebaseOptions;
}

export interface BootstrapIntegrations {
  [IntegrationsType.TYPE_GOOGLE_ANALYTICS]: BootstrapIntegrationScript;
  [IntegrationsType.TYPE_FACEBOOK_PIXEL]: BootstrapIntegrationScript;
  [IntegrationsType.TYPE_HOTJAR]: BootstrapIntegrationScript;
  [IntegrationsType.TYPE_ZENDESK_CHAT]: BootstrapIntegrationScript;
  [IntegrationsType.TYPE_STRIPE_AFFIRM]: BootstrapIntegrationStripe;
  [IntegrationsType.TYPE_PAYPAL]: BootstrapIntegrationPayPal;
  [IntegrationsType.TYPE_CDN_DOMAIN]: BootstrapIntegrationCDN;
  [IntegrationsType.TYPE_CUSTOM_SCRIPTS]: BootstrapIntegrationScripts;
  [IntegrationsType.TYPE_GTM]: BootstrapIntegrationGTM;
  [IntegrationsType.TYPE_FIREBASE]?: BootstrapIntegrationFirebase;
}

export interface NavMenuItemTranslation extends ITranslation {
  summary: string;
  id: number;
}
export interface NavMenuItem {
  id: number;
  parent_id: number | string | null;
  type: number;
  url: string;
  sort_order: number;
  is_title: boolean;
  icon?: string;
  image?: string;
  translations: NavMenuItemTranslation[];
  currentTranslation?: NavMenuItemTranslation;
}

export interface NavMenu {
  name: string;
  code: string;
  items: NavMenuItem[];
}

export interface Menu {
  [key: string]: NavMenu;
}

export interface MaterialCategoryTranslation extends ITranslation {
  title: string;
  short_description?: string;
}

export interface MaterialCategory {
  id: number;
  slug: string;
  sku: string;
  title?: string;
  sort_order: number | null;
  view_type?: string;
  show_tooltip: boolean;
  range_step: number;
  data_type: string;
  advanced_filter: boolean;
  translations: MaterialCategoryTranslation[];
  currentTranslation: MaterialCategoryTranslation;
  sku_enabled?: boolean;
  // short_description?: string;
}

export interface BootstrapData {
  enabled_integrations: IntegrationsType[];
  integrations: BootstrapIntegrations;
  settings: BootstrapSettings;
  material_categories: MaterialCategory[];
  menu: Menu;
  attributes: any[];
  version: number;
}

export interface BootstrapResponse {
  data: BootstrapData;
  meta: any[];
}

export interface StorePayloadIntegrationCallbackData {
  integrationName: string;
  status: boolean;
}

export interface StorePayloadIntegrationObjectNameData {
  integrationName: string;
  integrationObjectName: string;
}
